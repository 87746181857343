import { GetStaticProps } from 'next';
import { checkRequestErrors, getServerCLient } from '@/sharedAPI';
import { deviceType, parseSSGParams, serialize, timeInSeconds } from '@/sharedLib';

export { LoginPage as default } from '@/pages/login';

export const getStaticProps: GetStaticProps = async (context) => {
  const { device } = parseSSGParams(context.params);
  const { ssrCache } = getServerCLient();

  const isValidPath = Object.values(deviceType).includes(device);
  if (!isValidPath) {
    return {
      notFound: true,
    };
  }

  const { urqlState, revalidate } = checkRequestErrors(ssrCache.extractData(), timeInSeconds.WEEK);

  const props = serialize({
    urqlState,
    device,
  });

  return {
    props: props,
    revalidate,
  };
};

export const getStaticPaths = async () => {
  return {
    paths: [],
    fallback: 'blocking',
  };
};
