import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import {
  FormControl,
  FormErrorMessage,
  IconButton,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
} from '@chakra-ui/react';
import { FC, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { INVALID_CHARACTERS_MSG, REQUIRED_FIELD_MSG, passwordRegExp } from '@/sharedLib';

interface PasswordFieldProps extends Omit<InputProps, 'type'> {
  name?: string;
  reserveSpaceForError?: boolean;
}

const INPUT_NAME = 'password';

const PasswordField: FC<PasswordFieldProps> = ({
  name = 'password',
  reserveSpaceForError = true,
}) => {
  const { register } = useFormContext();
  const { fieldState } = useController({
    name,
  });
  const { invalid, error } = fieldState;
  const [isShowPassword, setIsShowPassword] = useState(false);

  return (
    <FormControl
      isInvalid={invalid}
      isRequired
    >
      <InputGroup>
        <Input
          id={INPUT_NAME}
          mb={reserveSpaceForError && !error?.message ? 'lg' : 0}
          placeholder={INPUT_NAME}
          type={isShowPassword ? 'text' : 'password'}
          {...register(INPUT_NAME, {
            required: REQUIRED_FIELD_MSG,
            pattern: {
              value: passwordRegExp,
              message: INVALID_CHARACTERS_MSG,
            },
          })}
        />
        <InputRightElement onMouseDown={(e) => e.preventDefault()}>
          <IconButton
            aria-label='show-password'
            color='typography.pure'
            icon={isShowPassword ? <ViewOffIcon /> : <ViewIcon />}
            size='xs'
            onClick={() => setIsShowPassword(!isShowPassword)}
          />
        </InputRightElement>
      </InputGroup>
      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
};

export default PasswordField;
